import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  LazyRouteFallback,
  ProgressSpinner,
  noFocus,
  spacing,
} from 'folio-common-components';
import * as React from 'react';
import { lazyWithPreload } from 'react-lazy-with-preload';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useMediaLayout } from 'use-media';
import { ErrorRoute } from './components/ErrorRoute';
import { Footer } from './components/Footer';
import { Grid } from './components/Grid';
import { HeaderBar, type MenuItem } from './components/HeaderBar';
import { MessagesBar } from './components/MessagesBar';
import { Page } from './components/Page';
import { RouteErrorBoundary } from './components/RouteErrorBoundary';
import { TopLevelMenuLarge } from './components/TopLevelMenu';
import { Intercom } from './components/intercom';
import { GlobalStyles } from './global-styles';
import { useScrollToTopOnPathChange } from './hooks/scroll-to-top';
import { mountPath, pageMap, pageTitleMap, pathMap } from './paths';
import HomePage from './routes/home';
import ResetBankSelection from './routes/reset-bank-selection';
// Don't do this async, as that will have to wait for the bundle to execute before loading this route.
import StepPage1 from './routes/step1';
import { type RootState, useActions, useSelector } from './state/';

const suspenseFallback = <LazyRouteFallback />;

const StepPage2 = lazyWithPreload(() => import('./routes/step2'));
const StepPage3 = lazyWithPreload(() => import('./routes/step3'));
const StepPage4 = lazyWithPreload(() => import('./routes/step4'));
const StepPage5 = lazyWithPreload(() => import('./routes/step5'));
const SigningPage = lazyWithPreload(() => import('./routes/signing'));
const ProgressPage = lazyWithPreload(() => import('./routes/progress'));
const AboutPage = lazyWithPreload(() => import('./routes/about'));
const FoundingFaq = lazyWithPreload(() => import('./routes/founding-faq'));
const FindOutMorePage = lazyWithPreload(() => import('./routes/find-out-more'));
const CitizenshipPage = lazyWithPreload(() => import('./routes/citizenship'));

// Preload everything
StepPage2.preload();
StepPage3.preload();
StepPage4.preload();
StepPage5.preload();
SigningPage.preload();
ProgressPage.preload();
FindOutMorePage.preload();
CitizenshipPage.preload();

const menuItems: MenuItem[] = [
  {
    href: pageMap.step1,
    text: pageTitleMap.step1,
  },
  {
    href: pageMap.step2,
    text: pageTitleMap.step2,
  },
  {
    href: pageMap.step3,
    text: pageTitleMap.step3,
  },
  {
    href: pageMap.step4,
    text: pageTitleMap.step4,
  },
  {
    href: pageMap.step5,
    text: pageTitleMap.step5,
  },
];

function titleFromPath(path: string) {
  const page = pathMap[path];
  return pageTitleMap[page] ?? '';
}

const useTitle = (title: string) => {
  React.useEffect(() => {
    document.title =
      title === '' ? 'Start AS med Fiken' : `${title} – Start AS med Fiken`;
  }, [title]);
};

type HideNav = { hideNav: boolean };

const Wrapper = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const hideNavStyles = ({ hideNav }: HideNav) => {
  if (hideNav) {
    return css`
      grid-template-columns: 1fr;
    `;
  }

  return null;
};

const Content = styled(Page)`
  display: grid;
  grid-template-columns: 240px minmax(590px, 1fr) minmax(0, 240px);

  ${hideNavStyles};
`;

const MainGrid = styled(Grid.withComponent('main'))`
  ${noFocus};
`;

function selector(state: RootState) {
  return {
    isOpen: !state.founding || state.founding.signingStatus === 'OPEN',
    booted: state.app.booted,
    bootFailed: state.app.bootFailed,
    slowBoot: !state.app.booted && state.app.bootIsSlow,
    pristine: state.app.pristine,
  };
}

function getShouldRedirectToStatus(opts: {
  pathname: string;
  isOpen: boolean;
}) {
  const { isOpen, pathname } = opts;
  const onSigningPage = pathname === pageMap.signing;
  const onAboutPage = pathname === pageMap.about;
  const onFoundingFaqPage = pathname === pageMap.foundingFaq;
  const onFindOutMorePage = pathname === pageMap.findOutMore;
  const onCitizenshipPage = pathname === pageMap.citizenship;

  const shouldRedirectToStatus =
    !onSigningPage &&
    !onAboutPage &&
    !onFoundingFaqPage &&
    !onFindOutMorePage &&
    !onCitizenshipPage &&
    pathname !== pageMap.status &&
    !isOpen;

  return shouldRedirectToStatus;
}

const SuspendedRoute: React.FC<React.PropsWithChildren> = ({ children }) => (
  <React.Suspense fallback={suspenseFallback}>
    <RouteErrorBoundary>{children}</RouteErrorBoundary>
  </React.Suspense>
);

const AppBody: React.FC = () => {
  return (
    <Routes>
      <Route path={pageMap.home} element={<HomePage />} />
      <Route
        path={pageMap.step1}
        element={
          <SuspendedRoute>
            <StepPage1 />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.step2}
        element={
          <SuspendedRoute>
            <StepPage2 />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.step3}
        element={
          <SuspendedRoute>
            <StepPage3 />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.step4}
        element={
          <SuspendedRoute>
            <StepPage4 />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.step5}
        element={
          <SuspendedRoute>
            <StepPage5 />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.signing}
        element={
          <SuspendedRoute>
            <SigningPage />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.status}
        element={
          <SuspendedRoute>
            <ProgressPage />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.about}
        element={
          <SuspendedRoute>
            <AboutPage />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.foundingFaq}
        element={
          <SuspendedRoute>
            <FoundingFaq />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.findOutMore}
        element={
          <SuspendedRoute>
            <FindOutMorePage />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.resetBankSelection}
        element={
          <SuspendedRoute>
            <ResetBankSelection />
          </SuspendedRoute>
        }
      />
      <Route
        path={pageMap.citizenship}
        element={
          <SuspendedRoute>
            <CitizenshipPage />
          </SuspendedRoute>
        }
      />
      <Route path="*" element={<Navigate to={mountPath} replace={true} />} />
    </Routes>
  );
};

function getShouldShowNavigation(path: string, pristine: boolean) {
  if (
    path === pageMap.status ||
    path === pageMap.signing ||
    path === pageMap.about ||
    path === pageMap.foundingFaq ||
    path === pageMap.findOutMore ||
    path === pageMap.citizenship
  ) {
    return false;
  } else if (path === '/' || path === mountPath || path === `${mountPath}/`) {
    return !pristine;
  } else {
    return true;
  }
}

function useAppTimers() {
  const { appThunks, editorThunks } = useActions();

  React.useEffect(() => {
    const savingTimerId = window.setInterval(editorThunks.saveSnapshot, 2000);
    const toastTimerId = window.setInterval(appThunks.updateMessageToast, 2000);

    return () => {
      clearInterval(savingTimerId);
      clearInterval(toastTimerId);
    };
  }, [appThunks, editorThunks]);
}

function useBootup() {
  const { search } = useLocation();
  const { appThunks } = useActions();
  const booted = useSelector(e => e.app.booted);

  React.useEffect(() => {
    if (!booted) {
      const searchParams = new URLSearchParams(search);
      const readOnly = searchParams.has('readonly');
      const queryId = searchParams.get('id') || undefined;
      appThunks.boot(queryId, readOnly);
    }
  }, [appThunks, booted, search]);
}

function useIdQueryAppender() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const id = useSelector(e => e.editor.id);
  const pristine = useSelector(e => e.app.pristine);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const currentSetId = searchParams.get('id');
    if (id && currentSetId !== id && !pristine) {
      searchParams.set('id', id);
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }, [id, navigate, pristine, search]);
}

const Layout: React.FC<React.PropsWithChildren> = props => {
  const { pristine } = useSelector(selector);
  const { pathname } = useLocation();
  const shouldShowNavigation = getShouldShowNavigation(pathname, pristine);
  const isSmallSize = useMediaLayout('(max-width: 880px)');
  const hideNav = isSmallSize || !shouldShowNavigation;

  return (
    <>
      <GlobalStyles />
      <Wrapper>
        <HeaderBar
          menuItems={menuItems}
          navigationVisible={shouldShowNavigation}
        />
        <MessagesBar />
        <div>
          {!hideNav ? (
            <Page>
              <div css={spacing.getSpacing([32], 'margin-horizontal')}>
                <TopText />
              </div>
            </Page>
          ) : null}
          <Content hideNav={hideNav}>
            {hideNav ? null : <TopLevelMenuLarge menuItems={menuItems} />}
            <div>
              {hideNav ? (
                <Grid>
                  <TopText />
                </Grid>
              ) : null}
              <MainGrid tabIndex={-1}>{props.children}</MainGrid>
            </div>
          </Content>
        </div>
        <Footer />
      </Wrapper>
      <Intercom />
    </>
  );
};

export const TopText: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <div
      css={css`
        font-size: 30px;
        font-weight: 400;
        margin: 48px 0;

        @media (max-width: 880px) {
          font-size: 20px;
          margin: 16px 0;
        }
      `}
    >
      {pathname === pageMap.step1 ||
      pathname === pageMap.step2 ||
      pathname === pageMap.step3 ||
      pathname === pageMap.step4 ||
      pathname === pageMap.step5 ? (
        <>Selskaps&shy;registrering og signering av stiftelses&shy;dokumenter</>
      ) : pathname === pageMap.status ||
        pathname === pageMap.citizenship ||
        pathname === pageMap.findOutMore ? (
        <>Start AS med Fiken</>
      ) : null}
    </div>
  );
};

export const App: React.FC = () => {
  const { booted, slowBoot, bootFailed, isOpen } = useSelector(selector);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useScrollToTopOnPathChange();
  useAppTimers();
  useBootup();
  useTitle(titleFromPath(pathname));
  useIdQueryAppender();

  const shouldRedirectToStatus = getShouldRedirectToStatus({
    pathname,
    isOpen,
  });

  React.useEffect(() => {
    if (shouldRedirectToStatus) {
      navigate({ pathname: pageMap.status, search });
    }
  }, [navigate, search, shouldRedirectToStatus]);

  if (bootFailed) {
    return (
      <Layout>
        <ErrorRoute />
      </Layout>
    );
  } else if (slowBoot) {
    return (
      <Layout>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <ProgressSpinner size={48} />
          <p>Henter selskapet ditt</p>
        </div>
      </Layout>
    );
  } else if (!booted) {
    return null;
  } else {
    return (
      <Layout>
        <AppBody />
      </Layout>
    );
  }
};
