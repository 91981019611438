import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fonts, spacing } from 'folio-common-components';
import * as React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useValidationStatusByPage } from '../../utils/validators';
import type { MenuItem } from '../HeaderBar';
import { ArrowDownIcon, ArrowRightIcon, CheckmarkIcon } from '../icons';

type NavLinkProps = {
  href: string;
  text: string;
  isValid: boolean;
};

interface Props {
  menuItems: readonly MenuItem[];
}

const alignmentFix = css`
  position: relative;
  top: 1px;
`;

const Index = styled.span`
  ${fonts.font200medium};
  ${alignmentFix};
`;

const circle = css`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;

const linkActive = css`
  border-color: var(--purple-800);

  :hover {
    border-color: var(--purple-800);
  }
`;

const smallLink = css`
  &:not(:first-of-type) {
    margin-left: 2px;
  }
  text-decoration: none;
  border-radius: 50%;
  padding: 2px;
  border: 2px solid transparent;
  -webkit-tap-highlight-color: transparent;

  :hover {
    border-color: var(--purple-800);
  }

  :active {
    ${linkActive};
  }
`;

export const CircleContent = styled.span<{
  color: string;
  backgroundColor: string;
  isCurrent: boolean;
}>`
  ${circle};
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
`;

const largeLink = css`
  display: flex;
  align-items: center;
  padding: 4px;
  text-decoration: none;
  color: var(--black-500);
  margin-bottom: 16px;
  border-radius: 30px;
  ${fonts.font300book};
  border: 2px solid transparent;
  position: relative;
  -webkit-tap-highlight-color: transparent;

  /* This makes sure there are no uncklickable areas between menu items, which looks
  better when moving the cursor between items since it avoids flashing the cursor. */
  ::before {
    content: '';
    position: absolute;
    /* 10 is: 16 (space between menu items) / 2 + 2 (border) */
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
  }

  :hover {
    border-color: var(--purple-400);
  }

  @media (prefers-contrast: more) {
    :hover {
      border-color: var(--purple-800);
    }
  }

  :active {
    ${linkActive};
  }
`;

const TopLevelMenuLinkSmall: React.FC<NavLinkProps & { index: number }> = ({
  href,
  text,
  index,
  isValid,
}) => {
  const isActive = useMatch(href) != null;

  return (
    <NavLink
      css={[smallLink, isActive && linkActive]}
      to={{ pathname: href, search: window.location.search }}
      title={text}
      aria-label={`Steg ${index}: ${text}`}
    >
      <CircleContent
        isCurrent={isActive}
        backgroundColor={
          isValid
            ? 'var(--green-700)'
            : isActive
            ? 'var(--purple-800)'
            : 'var(--purple-400)'
        }
        color={isValid || isActive ? '#fff' : 'var(--purple-800)'}
      >
        {isValid ? (
          <CheckmarkIcon />
        ) : isActive ? (
          <ArrowDownIcon />
        ) : (
          <Index>{index}</Index>
        )}
      </CircleContent>
    </NavLink>
  );
};

const TopLevelMenuLinkLarge: React.FC<NavLinkProps & { index: number }> = ({
  href,
  text,
  index,
  isValid,
}) => {
  const isActive = useMatch(href) != null;

  return (
    <NavLink
      css={[
        largeLink,
        isActive && linkActive,
        isValid &&
          !isActive &&
          css`
            color: var(--grey-900);
          `,
      ]}
      to={{ pathname: href, search: window.location.search }}
      aria-label={`Steg ${index}: ${text}`}
    >
      <CircleContent
        isCurrent={isActive}
        backgroundColor={
          isValid
            ? 'var(--green-700)'
            : isActive
            ? 'var(--purple-800)'
            : 'var(--purple-400)'
        }
        color={isValid || isActive ? '#fff' : 'var(--purple-800)'}
        css={spacing.getSpacing([8], 'margin-right')}
      >
        {isValid ? (
          <CheckmarkIcon />
        ) : isActive ? (
          <ArrowRightIcon />
        ) : (
          <Index>{index}</Index>
        )}
      </CircleContent>{' '}
      {text}
    </NavLink>
  );
};

const TopLevelMenuSmall: React.FC<Props> = ({ menuItems, ...rest }) => {
  const status = useValidationStatusByPage();
  return (
    <nav
      aria-label="Hovednavigasjon"
      css={css`
        display: flex;
      `}
      {...rest}
    >
      {menuItems.map(({ href, text }, index) => (
        <TopLevelMenuLinkSmall
          key={href}
          href={href}
          text={text}
          index={index + 1}
          isValid={status.has(href)}
        />
      ))}
    </nav>
  );
};

const TopLevelMenuLarge: React.FC<Props> = ({ menuItems, ...rest }) => {
  const status = useValidationStatusByPage();
  return (
    <nav
      aria-label="Hovednavigasjon"
      css={css`
        padding: 0 1rem 0 calc(2rem - 6px);
      `}
      {...rest}
    >
      {menuItems.map(({ href, text }, index) => (
        <TopLevelMenuLinkLarge
          key={href}
          href={href}
          text={text}
          index={index + 1}
          isValid={status.has(href)}
        />
      ))}
    </nav>
  );
};

export { TopLevelMenuSmall, TopLevelMenuLarge };
