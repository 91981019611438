import { VisuallyHidden, spacing } from 'folio-common-components';
import * as React from 'react';
import { ContinueLink } from '../../components/ContinueLink';
import { FormOwnerForm } from '../../components/FormOwnerForm';
import { PageCard, Section } from '../../components/Section';
import { PageHeading } from '../../components/page-heading';
import { SectionHeading } from '../../components/section-heading';
import { Labels } from '../../messages';
import { pageMap } from '../../paths';

const StepPage1: React.FC = () => (
  <>
    <PageCard>
      <PageHeading>Om deg</PageHeading>

      <Section>
        <VisuallyHidden>
          <SectionHeading>Litt om deg</SectionHeading>
        </VisuallyHidden>
        <p css={spacing.getSpacing([32], 'margin-bottom')}>
          Vi må vite hvem som fyller ut skjemaet. Det blir ikke delt eller
          publisert noe sted.
        </p>
        <FormOwnerForm />
      </Section>
    </PageCard>

    <ContinueLink to={pageMap.step2}>
      <Labels.Onward />
    </ContinueLink>
  </>
);

// React.lazy only supports default exports:
// https://reactjs.org/docs/code-splitting.html#named-exports
// eslint-disable-next-line import/no-default-export
export default StepPage1;
