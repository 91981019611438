import styled from '@emotion/styled';
import { spacing } from 'folio-common-components';

export const Section = styled.div`
  & + & {
    ${spacing.getSpacing([48], 'margin-top')};
  }
`;

Section.defaultProps = {
  // @ts-expect-error testid not in the typings
  'data-testid': 'section',
};

// FIXME: rename the folder
export const PageCard = styled.div`
  ${spacing.getSpacing([48], 'margin-bottom')};
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.1),
    0px 5px 15px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  ${spacing.getSpacing([16, 24], 'padding')};
`;
